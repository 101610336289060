import { RootState } from '@/store'
import { createSlice, Dispatch, createEntityAdapter } from '@reduxjs/toolkit'
import { ListItem } from '@/views/tron'
import { list } from '@/views/tron/api'
import { RequestParams } from '@/api/types'

const name = 'tron'

const adapter = createEntityAdapter<ListItem>({
  selectId: (item) => item._id
})

const slice = createSlice({
  name,
  initialState: adapter.getInitialState(),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    updateOne: adapter.updateOne,
    removeOne: adapter.removeOne
  }
})

export const { setAll, addOne, updateOne, removeOne } = slice.actions

// 异步Action
export function getTronList (params?: RequestParams) {
  return function (dispatch: Dispatch) {
    return list<ListItem[]>(params).then(({ data, success }) => {
      if (success) {
        dispatch(setAll(data))
      }
    })
  }
}

export const { selectAll } = adapter.getSelectors((state: RootState) => state[name])

export default slice
