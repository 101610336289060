'use strict'

const tgcool = {
  logo: require('@/assets/img/cool/logo_64.png'),
  email: 'mailto:tgcool.com@gmail.com',
  agent: 'fdby178',
  botId: '6049022695',
  title: 'TG酷电报群发系统',
  subtitle: '全网功能最全、最好用的电报群发系统',
  channel: 'https://t.me/tgcool_vip',
  telegram: 'https://t.me/tgcool_com',
  favicon: require('@/assets/img/cool/logo_32.png')
}
const miqitg = {
  logo: require('@/assets/img/st/logo_64.png'),
  email: 'mailto:y8tg.com@gmail.com',
  agent: 'miqi888',
  botId: '6049022695',
  title: '米奇飞机营销系统',
  subtitle: '全网功能最全、最好用的电报群发系统',
  channel: 'https://t.me/miqitgCOM',
  telegram: 'https://t.me/miqitg_com',
  favicon: require('@/assets/img/st/logo_32.png')
}

const domains = [
  {
    host: 'y8tg.com',
    logo: require('@/assets/img/logo_192.png'),
    email: 'mailto:y8tg.com@gmail.com',
    agent: undefined,
    botId: '6049022695',
    title: '永发电报群发系统',
    subtitle: '全网功能最全、最好用的电报营销群发系统',
    channel: 'https://t.me/y8tgGroup',
    telegram: 'https://t.me/y8tg_com',
    mutualBot: 'https://t.me/Y8MutualBot?start=714176255',
    favicon: '/favicon.ico'
  },
  {
    host: 'tgcool.com',
    ...tgcool
  },
  {
    host: 'www.tgcool.com',
    ...tgcool
  },
  {
    host: 'miqitg.com',
    ...miqitg
  },
  {
    host: 'www.miqitg.com',
    ...miqitg
  }
]

const domain = domains.find(i => i.host === window.location.host) || domains[0]

document.title = domain.title
document.getElementById('favicon').href = domain.favicon

export default domain
