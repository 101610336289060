import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router'
import { isObject } from '@/utils'
import { isMobile } from '@/utils/hooks'
import classname from 'classnames/bind'
import Tour from '@/components/Tour'
import Menu from './Menu'
import Title from './Title'
import Header from './Header'
import Tabs from './Tabs'
import SettingDrawer from './SettingDrawer'
import MenuDrawer from './MenuDrawer'
import ErrorBoundary from '@/components/ErrorBoundary'
import scss from './index.module.scss'

const cn = classname.bind(scss)

export interface LayoutConfig {
  showTabs: boolean
  collapsed: boolean
  siderTheme: 'dark' | 'light'
  headerTheme: 'dark' | 'light'
  menuLocation: 'left' | 'top' | 'drawer'
}

function Index () {
  const [key, setKey] = useState<number>(Date.now())
  const [layout, setLayout] = useState<LayoutConfig>({
    showTabs: true,
    collapsed: false,
    siderTheme: 'dark',
    headerTheme: 'light',
    menuLocation: 'left',
    ...getLocalLayoutConfig()
  })
  const [openTour, setOpenTour] = useState<boolean>(false)
  const [openMenuDrawer, setOpenMenuDrawer] = useState<boolean>(false)
  const [openSettingDrawer, setOpenSettingDrawer] = useState<boolean>(false)

  useEffect(windowResizeHandle, [])
  useEffect(saveLayoutConfig, [layout])

  function getLocalLayoutConfig () {
    let config: any = localStorage.getItem('layout_config')
    config = config && JSON.parse(config)
    config = isObject(config) ? config : {}
    if (document.body.clientWidth < 768 || isMobile) {
      config.menuLocation = 'drawer'
    } else if (config.menuLocation === 'drawer') {
      config.menuLocation = 'left'
    }
    return config
  }

  function saveLayoutConfig () {
    localStorage.setItem('layout_config', JSON.stringify(layout))
  }

  function windowResizeHandle () {
    let timer: NodeJS.Timeout
    openTourFn()
    window.addEventListener('resize', handleResize)

    function handleResize () {
      clearTimeout(timer)
      timer = setTimeout(() => {
        const clientWidth = document.body.clientWidth
        let menuLocation: LayoutConfig['menuLocation']
        if (clientWidth > 768 && !isMobile) {
          menuLocation = 'left'
        } else {
          menuLocation = 'drawer'
        }

        setLayout(s => ({
          ...s,
          menuLocation,
          collapsed: clientWidth < 1000
        }))
        setOpenMenuDrawer(false)
      }, 100)
    }

    return () => window.removeEventListener('resize', handleResize)
  }

  function openTourFn () {
    if (!localStorage.getItem('tour')) {
      localStorage.setItem('tour', '1')
      if (isMobile) setOpenMenuDrawer(true)
      setTimeout(() => setOpenTour(true), 1000)
    }
  }

  return (
    <section className={scss.layout}>
      {
        layout.menuLocation === 'left' &&
        <aside className={cn(scss.layout__aside)} style={{ width: layout.collapsed ? 48 : 220 }}>
          <div className={cn(scss.aside, layout.siderTheme)}>
            <Title collapsed={layout.collapsed} theme={layout.siderTheme}/>
            <Menu mode={'inline'} theme={layout.siderTheme} collapsed={layout.collapsed}/>
          </div>
        </aside>
      }
      <section className={scss.layout__container}>
        <header id="layout_header" className={scss.layout__header}>
          <Header
            layout={layout}
            setKey={setKey}
            setLayout={setLayout}
            openMenuDrawer={openMenuDrawer}
            setOpenMenuDrawer={setOpenMenuDrawer}
            setOpenSettingDrawer={setOpenSettingDrawer}/>
          {
            layout.showTabs &&
            <Tabs/>
          }
        </header>
        <main id="layout_content" key={key} className={scss.layout__content}>
          <ErrorBoundary setKey={setKey}>
            <Outlet/>
          </ErrorBoundary>
        </main>
      </section>
      <SettingDrawer
        layout={layout}
        setLayout={setLayout}
        open={openSettingDrawer}
        setOpen={setOpenSettingDrawer}/>
      {
        layout.menuLocation === 'drawer' &&
        <MenuDrawer
          layout={layout}
          open={openMenuDrawer}
          setOpen={setOpenMenuDrawer}/>
      }
      <Tour open={openTour} setOpen={setOpenTour}/>
    </section>
  )
}

export default Index
