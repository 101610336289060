import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ListItem } from '@/views/telegram/contact'
import { RootState } from '@/store'

const name = 'contact'

const initialState: ListItem[] = []

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setContact (state, action: PayloadAction<ListItem[]>) {
      return action.payload
    }
  }
})

// 同步Action
export const { setContact } = slice.actions

export const getContact = (state: RootState) => state[name]

export default slice
