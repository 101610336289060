import http from '@/api/http'
import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'

// 获取支付方式列表
export function list<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/payment/list'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取可用支付方式
export function enableList<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/payment/enableList'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 添加支付方式
export function create<T = any> (params?: FormData, config?: AxiosRequestConfig) {
  const url = '/payment/create'
  config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    ...config
  }
  return http.post<ResponseData<T>, FormData>(url, params, config).then(r => r.data)
}

// 更新支付方式
export function update<T = any> (params?: FormData, config?: AxiosRequestConfig) {
  const url = '/payment/update'
  config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    ...config
  }
  return http.post<ResponseData<T>, FormData>(url, params, config).then(r => r.data)
}

// 更新支付方式
export function updateByJson<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/payment/updateByJson'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除支付方式
export function remove<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/payment/remove'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取类型
export function getType<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/payment/getType'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
