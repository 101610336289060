import md5 from 'md5'
import http from '@/api/http'
import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'

// 获取图片验证码
export function getCaptcha<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/common/getCaptcha'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取邮箱验证码
export function getEmailCode<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/common/getEmailCode'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取登录是否需要验证码
export function getIsNeedCaptcha<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/common/getIsNeedCaptcha'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 登录
export function login<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/login'
  params.password = md5(params.password)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 登录
export function loginByBot<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/loginByBot'
  params = {
    ...params,
    _notShowErrorTipsCode: 'N000010'
  }
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 谷歌登录
export function loginByGoogle<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/loginByGoogle'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 电报登录
export function loginByTelegram<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/loginByTelegram'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 注册
export function register<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/register'
  params.password = md5(params.password)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 快捷注册
export function fastRegister<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/fastRegister'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取用户信息
export function get<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/get'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取用户信息和菜单权限
export function getUserInfoAndMenus<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/getUserInfoAndMenus'
  params = {
    ...params,
    _isShowErrorTips: false
  }
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新密码（使用旧密码）
export function updatePassword<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/updatePassword'
  params.password = md5(params.password)
  params.newPassword = md5(params.newPassword)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 修改邮箱
export function updateEmail<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/updateEmail'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新密码（使用邮箱）
export function updatePasswordByEmail<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/updatePasswordByEmail'
  params.password = md5(params.password)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取用户余额
export function getBalance<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/getBalance'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
