import qs from 'qs'
import md5 from 'md5'
import axios, { AxiosRequestHeaders, AxiosRequestConfig, AxiosResponse } from 'axios'
import store from '@/store'
import config from '@/config'
import { RequestParams, ResponseData, CustomParams } from '@/api/types'
import { getUuid } from '@/utils/depend'
import { reverseString } from '@/utils'
import { message as antdMessage } from 'antd'
import { clearUser } from '@/store/slice/user'
import { selectById } from '@/store/slice/account'
import { translate } from '@/utils/translate'

interface AppAxiosRequestConfig extends AxiosRequestConfig {
  customParams?: CustomParams
}

interface AppAxiosResponse extends AxiosResponse {
  config: AppAxiosRequestConfig
}

// 创建 axios 实例
const _http = axios.create({
  timeout: 60 * 1000,
  baseURL: config.apiUrl
})

// 添加请求拦截器
_http.interceptors.request.use(request, error => {
  console.log('request error:\n', error)
  return Promise.reject(error)
})

// 添加响应拦截器
_http.interceptors.response.use(response, responseOnRejected)

function request (config: AppAxiosRequestConfig) {
  const qid = getUuid()
  const path = config.url!.split('?')[0]
  const state = store.getState()
  const token = state.user.token || ''
  const username = state.user.username || ''

  const headers = config.headers as AxiosRequestHeaders
  const contentType = headers['Content-Type'] as string
  const isFormData = /multipart\/form-data/.test(contentType)
  const paramsFiled = /get|delete/.test(String(config.method)) ? 'params' : 'data'
  const needSerialize = /application\/x-www-form-urlencoded/.test(contentType)

  const data = {
    ...config[paramsFiled]
  }
  const customParams = {
    _isNeedLoading: data._isNeedLoading,
    _isShowErrorTips: data._isShowErrorTips !== false,
    _notHandleResponse: data._notHandleResponse,
    _notShowErrorTipsCode: data._notShowErrorTipsCode
  }

  delete data._isNeedLoading
  delete data._isShowErrorTips
  delete data._notHandleResponse
  delete data._notShowErrorTipsCode

  if (!isFormData) {
    config[paramsFiled] = needSerialize ? qs.stringify(data) : data
  }

  const sign = reverseString(path.split('/').pop() + username + JSON.stringify(isFormData ? {} : data))

  config.headers = {
    qid,
    sign: md5(sign),
    token,
    username,
    ...config.headers
  }
  config.customParams = customParams

  return config
}

function response (response: AppAxiosResponse) {
  const {
    _isShowErrorTips,
    _notHandleResponse,
    _notShowErrorTipsCode
  } = response.config.customParams as CustomParams

  if (_notHandleResponse) return response

  const { code, message } = response.data as ResponseData
  const notTipsCode = ['N000000', 'N005001', 'N005002']
  const needLoginCode = ['N000004', 'N000005']

  const isTips = _isShowErrorTips && code !== _notShowErrorTipsCode && !~notTipsCode.indexOf(code)
  if (isTips) {
    antdMessage.error(translate(message))
  }
  if (needLoginCode.includes(code)) {
    store.dispatch(clearUser())
  }

  return response
}

function responseOnRejected (error: AppAxiosResponse & Error) {
  antdMessage.error(error.message)
  return Promise.reject(error.message)
}

function get<T = ResponseData, P extends RequestParams = RequestParams> (url: string, params?: P, config?: AxiosRequestConfig<P>) {
  config = {
    params,
    ...config
  }
  return _http.get<T>(url, config)
}

function put<T = ResponseData, P extends RequestParams = RequestParams> (url: string, params?: P, config?: AxiosRequestConfig<P>) {
  return _http.put<T>(url, params, config)
}

function post<T = ResponseData, P extends RequestParams = RequestParams> (url: string, params?: P, config?: AxiosRequestConfig<P>) {
  const _url = dispatchPath(url, params)
  if (!_url) return Promise.reject('未找到账号服务器')
  return _http.post<T>(_url, params, config)
}

function remove<T = ResponseData, P = RequestParams> (url: string, params?: P, config?: AxiosRequestConfig<P>) {
  config = {
    params,
    ...config
  }
  return _http.delete<T>(url, config)
}

// 封装常用请求方法
const http = {
  get,
  put,
  post,
  delete: remove
}

// 单个账号根据serverId请求到不同地址
function dispatchPath (url: string, params?: RequestParams) {
  if (/^\/tg\//.test(url)) {
    const state = store.getState()
    const account = selectById(state, String(params?._id))
    return account && `/${account.serverId}${url}`
  }
  return url
}

export default http
