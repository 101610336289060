import React from 'react'
import { Tour, TourProps } from 'antd'
import { TourStepInfo } from '@rc-component/tour/es/TourStep'
import { isMobile } from '@/utils/hooks'

interface Props {
  open: boolean
  setOpen: (b: boolean) => void
}

const Index: React.FC<Props> = (props) => {
  const { open, setOpen } = props
  const placement: TourStepInfo['placement'] = isMobile ? 'topLeft' : 'right'

  const steps: TourProps['steps'] = [
    {
      title: '账号管理',
      placement,
      description: '点击此处登录电报账号，编辑账号，自动回复等功能',
      target: () => document.getElementById('tour_/telegram') as HTMLElement
    },
    {
      title: '好友管理',
      placement,
      description: '点击此处查看账号的好友列表，添加好友，群发消息至好友等功能',
      target: () => document.getElementById('tour_/contact') as HTMLElement
    },
    {
      title: '群组管理',
      placement,
      description: '点击此处查看账号的群组列表，添加群组，群发消息至群组等功能',
      target: () => document.getElementById('tour_/channel') as HTMLElement
    },
    {
      title: '消息列表',
      placement,
      description: '点击此处查看账号消息，回复消息，转发消息等功能',
      target: () => document.getElementById('tour_/message') as HTMLElement
    },
    {
      title: '任务管理',
      placement,
      description: '点击此处查看任务列表，更新任务，暂停任务，批量账号群发、转发、私聊等功能',
      target: () => document.getElementById('tour_/task') as HTMLElement
    },
    {
      title: '使用帮助',
      placement,
      description: '点击此处查看使用教程和价格列表',
      target: () => document.getElementById('tour_/_help') as HTMLElement
    },
    {
      title: '联系客服',
      description: '点击此处联系客服或进入官方群组',
      target: () => document.getElementById('tour2') as HTMLElement
    },
    {
      title: '切换账号',
      description: '点击此处切换账号，使用不同的账号进行各类操作，可使用名称或手机号进行搜索',
      target: () => document.getElementById('tour3') as HTMLElement
    }
  ]

  return (
    <Tour
      open={open}
      steps={steps}
      onClose={() => setOpen(false)}/>
  )
}

export default Index
