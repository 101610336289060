import 'dayjs/locale/zh-cn'
import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import antdZhCN from 'antd/es/locale/zh_CN'
// import antdEnUS from 'antd/es/locale/en_US'
import Router from '@/router/index'
import ErrorBoundary from '@/components/ErrorBoundary'
import { ConfigProvider, message } from 'antd'
import { isMobile, useUserSelector } from '@/utils/hooks'
import { getUserInfoAndMenus } from '@/store/slice/user'
import { useAppDispatch } from '@/store'
import { StyleProvider } from '@ant-design/cssinjs'
import { getAccountList } from '@/store/slice/account'

dayjs.extend(duration)

message.config({
  top: isMobile ? window.innerHeight / 2 - 40 : undefined,
  maxCount: 1
})

function App () {
  const user = useUserSelector()
  const dispatch = useAppDispatch()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(componentDidMount, [user.token])

  function componentDidMount () {
    let timer: NodeJS.Timer
    hideLoading()

    if (user.token && !window.name) {
      dispatch(getAccountList())
      dispatch(getUserInfoAndMenus())
    }

    return () => clearInterval(timer)
  }

  function hideLoading () {
    const div = document.getElementById('loadingDom')
    const style = document.getElementById('loadingStyle')
    if (div && style) {
      div.style.animationName = 'zoom-out'
      div.addEventListener('animationend', () => {
        div.parentNode?.removeChild(div)
        style.parentNode?.removeChild(style)
      }, { once: true })
    }
  }

  return (
    <StyleProvider hashPriority="high">
      <ErrorBoundary>
        <ConfigProvider componentSize={'middle'} locale={antdZhCN}>
          <Router/>
        </ConfigProvider>
      </ErrorBoundary>
    </StyleProvider>
  )
}

export default App
