import http from '@/api/http'
import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'

// 列表
export function list<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  params = {
    sort: { _id: 'descend' },
    ...params
  }
  const url = '/tron/list'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 创建
export function create<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tron/create'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 创建
export function importAccount<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tron/importAccount'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新
export function update<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tron/update'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新余额
export function updateBalance<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tron/updateBalance'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 转账
export function transfer<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tron/transfer'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 私钥
export function getPrivateKey<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tron/getPrivateKey'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除
export function remove<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tron/remove'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
