import { RootState, AppThunk } from '@/store'
import { createSlice, PayloadAction, createSelector, Dispatch } from '@reduxjs/toolkit'
import { RequestParams } from '@/api/types'
import { ListItem as Role } from '@/views/roles'
import { ListItem as UserItem } from '@/views/users'
import * as api from '@/views/user/api'

interface Menu {
  _id: string
  path?: string
  icon?: string
  name: string
  enable: boolean
  auths: string[]
  parent?: string
  children: Menu[]
}

interface _User extends Omit<UserItem, 'role'> {
  role: {
    _id: Role['_id']
    name: Role['name']
    type: Role['type']
    menus: Menu[]
    account: Role['account']
  }
  token: string
}

type User = Partial<_User>

const name = 'user'

const initialState: User = {}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setUser (state, action: PayloadAction<User>) {
      return {
        ...state,
        ...action.payload
      }
    },
    clearUser () {
      return {}
    }
  }
})

// 同步Action
export const {
  setUser,
  clearUser
} = slice.actions

// 异步Action
export function login (params: RequestParams) {
  return function (dispatch: Dispatch) {
    return api.login<_User>(params).then((res) => {
      const { success, data } = res
      success && dispatch(setUser(data))

      return res
    })
  }
}

// 获取余额或押金
export function getBalance (): AppThunk {
  return function (dispatch) {
    return api.getBalance().then((res) => {
      const { success, data } = res
      success && dispatch(setUser(data))

      return res
    })
  }
}

// 获取用户信息
export function getUserInfo () {
  return function (dispatch: Dispatch) {
    return api.get<_User>().then((res) => {
      const { success, data } = res
      success && dispatch(setUser(data))

      return res
    })
  }
}

// 获取用户数据，包括角色/菜单/权限
export function getUserInfoAndMenus () {
  return function (dispatch: Dispatch) {
    return api.getUserInfoAndMenus<_User>().then((res) => {
      const { success, data } = res
      success && dispatch(setUser(data))

      return res
    })
  }
}

// 选择器
export const getUserSelector = (state: RootState) => state[name] as _User
export const getTokenSelector = createSelector(getUserSelector, state => state.token)
export const getMenusSelector = createSelector(getUserSelector, state => state.role?.menus || [])

export type { Menu }
export default slice
