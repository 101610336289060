import auth from './slice/auth'
import user from './slice/user'
import rate from './slice/rate'
import tron from './slice/tron'
import contact from './slice/contact'
import channel from './slice/channel'
import country from './slice/country'
import payment from './slice/payment'
import telegram from './slice/account'
import { combineReducers } from 'redux'

const reducer = combineReducers({
  [user.name]: user.reducer,
  [auth.name]: auth.reducer,
  [rate.name]: rate.reducer,
  [tron.name]: tron.reducer,
  [contact.name]: contact.reducer,
  [channel.name]: channel.reducer,
  [country.name]: country.reducer,
  [payment.name]: payment.reducer,
  [telegram.name]: telegram.reducer
})

export default reducer
