import { v1 as uuid } from 'uuid'
// import JsEncrypt from 'jsencrypt'
// import config from '@/config'
import dayjs from 'dayjs'
import { User } from '@/views/telegram/types'

/**
 * @description 获取uuid
 *
 * @return string
 */
export function getUuid () {
  return uuid()
}

/**
 * @description 加密字段
 *
 * @param {String} str 需要加密的文本
 * @return {String}
 */
// export function encrypted (str: string) {
//   // 新建JsEncrypt对象
//   const enCrypt = new JsEncrypt()
//   // 设置公钥
//   enCrypt.setPublicKey(config.publicKey)
//   // 加密数据
//   return enCrypt.encrypt(str)
// }

/**
 * @description 获取活跃状态文字
 * */
export function getStatusText (status?: User['status']): string {
  if (!status) return '很久没上线'
  switch (status._) {
    case 'userStatusOnline':
      return '在线'
    case 'userStatusOffline': {
      const now = Date.now() / 1000
      const wasOnline = status.was_online
      const diff = (now - wasOnline) / 60 // 分钟
      if (diff < 1) {
        return '刚刚上线'
      } else if (diff < 60) {
        return Math.floor(diff) + ' 分钟前上线'
      } else if (diff < 60 * 24) {
        return Math.floor(diff / 60) + ' 小时前上线'
      } else if (diff < 60 * 24 * 2) {
        return `昨天 ${dayjs(wasOnline * 1000).format('HH:mm')}上线`
      } else {
        return dayjs(wasOnline * 1000).format('YYYY-MM-DD') + ' 上线'
      }
    }
    case 'userStatusRecently':
      return '近期上线'
    case 'userStatusLastWeek':
      return '近期一周上线'
    case 'userStatusLastMonth':
      return '近期一月上线'
    default:
      return ''
  }
}

/**
 * @description 获取活跃状态排序
 * */
export function getStatusSort (status?: User['status']): number {
  if (!status) return 0
  switch (status._) {
    case 'userStatusOnline':
      return dayjs().unix()
    case 'userStatusOffline': {
      return status.was_online
    }
    case 'userStatusRecently':
      return 4
    case 'userStatusLastWeek':
      return 3
    case 'userStatusLastMonth':
      return 2
    default:
      return 1
  }
}
