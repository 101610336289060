export const usernameReg = /^(?![0-9]+$)[0-9A-z._]{6,20}$/
export const passwordReg = /^[0-9A-z-*/+.~!@#$%^&()]{6,20}$/
export const emailReg = /^[A-z0-9]+([_.][A-z0-9]+)*@([A-z0-9-]+\.)+[A-z]{2,6}$/

export function verifyUsername (str: string): boolean {
  return usernameReg.test(str)
}

export function verifyPassword (str: string): boolean {
  return passwordReg.test(str)
}

export function verifyEmail (str: string): boolean {
  return emailReg.test(str)
}
