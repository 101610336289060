import React from 'react'
import classname from 'classnames/bind'
import { useNavigate } from 'react-router'
import domain from '@/config/domain'
import scss from './index.module.scss'

const cn = classname.bind(scss)

interface Props {
  width?: number
  theme: 'dark' | 'light'
  collapsed: boolean
}

const Title: React.FC<Props> = (props) => {
  const { theme, width } = props
  const navigate = useNavigate()
  return (
    <h1
      onClick={() => navigate('/')}
      className={cn(scss.title, theme)} style={{ width }}>
      <img className={scss.title__icon} src={domain.logo as any} alt=""/>
      <span className={scss.title__text}>{domain.title}</span>
    </h1>
  )
}

export default Title
