import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'
import http from '@/api/http'

// 全部
export function all<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/telegram/all'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 列表
// export function list<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
//   const url = '/telegram/list'
//   return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
// }

// 创建
export function create<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/telegram/create'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新
export function update<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/telegram/update'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新
export function updates<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/telegram/updates'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新
export function updateSort<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/telegram/updateSort'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// // 更新状态
// export function updateEnable<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
//   const url = '/telegram/updateEnable'
//   return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
// }

// 删除
export function remove<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/telegram/remove'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除
export function removes<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/telegram/removes'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取账号数据统计
export function getAccountCount<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/telegram/getAccountCount'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function getCountriesList<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/telegram/getCountriesList'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function getApiIdList<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/telegram/getApiIdList'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
