import React from 'react'
import { antIcons } from '@/components/Icon/iconList'
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon'

interface IconProps extends IconComponentProps {
  iconId?: string
}

function Index ({ iconId, ...props }: IconProps) {
  const AntIcon = iconId && antIcons[iconId]

  return (
    AntIcon ? <AntIcon {...props}/> : null
  )
}

export default Index
