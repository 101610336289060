import React, { Dispatch, SetStateAction } from 'react'
import { Drawer, Tooltip, Select, Divider, Switch } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { LayoutConfig } from '@/components/Layout'
import scss from './index.module.scss'

interface Props {
  layout: LayoutConfig
  setLayout: Dispatch<SetStateAction<LayoutConfig>>
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const SettingDrawer: React.FC<Props> = (props) => {
  const { open = false, layout, setLayout, setOpen } = props
  const { showTabs, siderTheme, headerTheme, menuLocation } = layout

  const themeList = [
    {
      value: 'dark',
      label: '暗色'
    },
    {
      value: 'light',
      label: '亮色'
    }
  ]
  const layoutList = [
    {
      key: 'left',
      title: '侧边菜单布局',
      img: require('./img/menu_in_left.svg').default
    },
    {
      key: 'top',
      title: '顶部菜单布局',
      img: require('./img/menu_in_head.svg').default
    }
  ]

  function onClose () {
    setOpen(false)
  }

  function assignLayoutConfig (state: Record<string, any>) {
    setLayout(s => ({ ...s, ...state }))
  }

  return (
    <Drawer
      open={open}
      width={300}
      closable={true}
      placement="right"
      onClose={onClose}>
      <div className={scss.setting}>
        <h3 className={scss.setting__title}>
          主题色
        </h3>
        <div className={scss.setting__nav}>
          <div className={scss['setting__nav-item']}>
            <span>
              头部风格
            </span>
            <Select
              value={headerTheme}
              style={{ width: 80 }}
              onChange={v => assignLayoutConfig({ headerTheme: v })}>
              {
                themeList.map(item => (
                  <Select.Option value={item.value} key={item.value}>
                    {item.label}
                  </Select.Option>
                ))
              }
            </Select>
          </div>
          <div className={scss['setting__nav-item']}>
            <span>
              侧边栏风格
            </span>
            <Select
              value={siderTheme}
              style={{ width: 80 }}
              onChange={v => assignLayoutConfig({ siderTheme: v })}
            >
              {
                themeList.map(item => (
                  <Select.Option value={item.value} key={item.value}>
                    {item.label}
                  </Select.Option>
                ))
              }
            </Select>
          </div>
        </div>
        <Divider/>
        <h3 className={scss.setting__title}>
          导航模式
        </h3>
        <div className={scss.setting__checkbox}>
          {
            layoutList.map((item, index) => (
              <Tooltip key={index} title={item.title}>
                <div onClick={() => assignLayoutConfig({ menuLocation: item.key })}
                  className={scss['setting__checkbox-item']}>
                  <img src={item.img} alt=""/>
                  {
                    item.key === menuLocation &&
                    <span className={scss['setting__icon-checked']}>
                      <CheckOutlined/>
                    </span>
                  }
                </div>
              </Tooltip>
            ))
          }
        </div>
        <div className={scss.setting__nav}>
          <div className={scss['setting__nav-item']}>
            <span>
              显示标签
            </span>
            <Switch
              checked={showTabs}
              onChange={v => assignLayoutConfig({ showTabs: v })}/>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default SettingDrawer
