import http from '@/api/http'
import { AxiosRequestConfig } from 'axios'
import { ResponseData, RequestParams } from '@/api/types'
import { transformRequestDataToFormData } from '@/utils'

export const uploadConfig = {
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  transformRequest: transformRequestDataToFormData
}

// // 获取token，多次调用只请求一次
// let getWebTokenResult: Promise<string | null> | null = null
//
// export function getWebToken (params?: RequestParams, config?: AxiosRequestConfig) {
//   params = {
//     _isNeedToken: false,
//     _isNeedLoading: true,
//     ...params
//   }
//   const url = '/common/getCaptcha?name=2'
//   if (!getWebTokenResult) {
//     getWebTokenResult = http.post<ResponseData<string>>(url, params, config).then(({ data }) => {
//       const {
//         data: _data,
//         success
//       } = data
//       getWebTokenResult = null
//       return success ? _data : null
//     }).catch(() => {
//       getWebTokenResult = null
//       return null
//     })
//   }
//   return getWebTokenResult
// }

// 获取国家列表
export function getCountry<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/common/getCountry'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取地区列表
export function getCityLevel<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/common/getCityLevel'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除地区文件缓存
export function removeJsonCache<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/common/removeJsonCache'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取uid
export function getUid<T = number> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/common/getUid'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 上传文件
export function uploadFile<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/common/uploadFile'
  config = Object.assign(uploadConfig, config)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除文件
export function removeFile<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/common/removeFile'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
