import React, {
  useMemo,
  useState,
  ReactNode,
  ReactElement,
  FC,
  useLayoutEffect
} from 'react'
import { Rule } from 'antd/es/form'
import { ColumnGroupType, ColumnType, FilterValue, SortOrder } from 'antd/es/table/interface'
import {
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  SearchOutlined,
  SyncOutlined
} from '@ant-design/icons'
import {
  Divider,
  Button,
  Input,
  Row,
  Col,
  PaginationProps,
  TreeSelectProps,
  InputNumber,
  TagProps,
  Tooltip,
  DatePicker,
  Select
} from 'antd'
import { useSelector } from 'react-redux'
import { getMenusSelector, getUserSelector } from '@/store/slice/user'
import { getPlatform, getTableScrollY, getTableScrollX } from '@/utils'
import { getAuthsSelector } from '@/store/slice/auth'
import { getCountrySelector } from '@/store/slice/country'
import { RangePickerProps } from 'antd/es/date-picker'
import moment from 'dayjs'
import Icon from '@/components/Icon'

interface Columns {
  width?: string | number
  children?: Columns[]
}

export interface TelegramInfo {
  id?: string | number
  phone?: string
  title?: string
  premium?: boolean
  username?: string
  first_name?: string
  last_name?: string

  [key: string]: any
}

const searchSpace = 10

export type TColumnsType<RecordType = unknown> = (ColumnGroupType<RecordType> | ColumnType<RecordType> | false)[]

export type Status = 'review' | 'approved' | 'rejected'

export type SortOrderState = Record<string, SortOrder | undefined>

export type FilterValueState = Record<string, FilterValue | null>

export const isMobile = getPlatform() !== 'pc'

export const paginationProps: PaginationProps = {
  size: 'small',
  total: 0,
  current: 1,
  pageSize: 20,
  showTotal (total) {
    return `共 ${total} 条`
  },
  pageSizeOptions: [20, 50, 100, 200],
  // simple: true,
  // showQuickJumper: true,
  showSizeChanger: true
}

export const requiredInputRule: [Rule] = [
  {
    required: true,
    pattern: /\S/,
    message: '请输入'
  }
]
export const requiredSelectRule: [Rule] = [
  {
    required: true,
    message: '请选择'
  }
]
export const addressRule: [Rule] = [
  {
    required: true,
    pattern: /^[A-z0-9]{34}$/,
    message: '请输入34位TRC20钱包地址'
  }
]

export const statusColorAndIcon: Record<Status, { icon: ReactElement, color: TagProps['color'] }> = {
  review: {
    icon: <SyncOutlined spin/>,
    color: 'processing'
  },
  approved: {
    icon: <CheckCircleOutlined/>,
    color: 'success'
  },
  rejected: {
    icon: <CloseCircleOutlined/>,
    color: 'error'
  }
}

export function useAuthsSelector () {
  return useSelector(getAuthsSelector)
}

export function useUserSelector () {
  return useSelector(getUserSelector)
}

export function useCountrySelector () {
  return useSelector(getCountrySelector)
}

export function useGetColumnSearchProps<RecordType extends Record<string, any>> (key: string, inputType: 'number' | 'text' = 'text', autoFilter?: true) {
  const onFilter: ColumnType<RecordType>['onFilter'] = (value, record) => {
    return String(record[key]).toLowerCase().includes(String(value).toLowerCase())
  }

  const filterIcon: ColumnType<RecordType>['filterIcon'] = <SearchOutlined/>

  const filterDropdown: ColumnType<RecordType>['filterDropdown'] = (props) => {
    const { selectedKeys, setSelectedKeys, confirm, clearFilters } = props

    function handelClear () {
      clearFilters && clearFilters()
      confirm()
    }

    return (
      <div style={{ padding: searchSpace }}>
        {
          inputType === 'number'
            ? <InputNumber pattern="\d*"
              id={'filter_input_' + key}
              value={selectedKeys[0]}
              style={{ width: 200 }}
              controls={false}
              placeholder={'请输入'}
              onChange={v => setSelectedKeys(v ? [v] : [])}
              onPressEnter={() => confirm({ closeDropdown: false })}/>
            : <Input
              id={'filter_input_' + key}
              value={selectedKeys[0]}
              style={{ width: 200 }}
              placeholder={'请输入'}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm({ closeDropdown: false })}/>
        }
        <Row style={{ marginTop: searchSpace }} gutter={searchSpace}>
          <Col span={12}>
            <Button
              size="small"
              block
              onClick={handelClear}>
              重置
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              size="small"
              icon={<SearchOutlined/>}
              block
              onClick={() => confirm()}>
              搜索
            </Button>
          </Col>
          {/* <Col span={6}> */}
          {/*   <Button */}
          {/*     type="link" */}
          {/*     size="small" */}
          {/*     block */}
          {/*     onClick={() => confirm({ closeDropdown: false })}> */}
          {/*     筛选 */}
          {/*   </Button> */}
          {/* </Col> */}
        </Row>
      </div>
    )
  }

  const onFilterDropdownOpenChange: ColumnType<RecordType>['onFilterDropdownOpenChange'] = (open) => {
    if (open) {
      setTimeout(() => {
        const input = document.getElementById('filter_input_' + key) as HTMLInputElement
        input.select()
      }, 100)
    }
  }

  return useMemo(() => ({
    onFilter: autoFilter ? onFilter : undefined,
    filterIcon,
    filterDropdown,
    onFilterDropdownOpenChange
  }), [autoFilter])
  // return {
  //   onFilter: autoFilter ? onFilter : undefined,
  //   filterIcon,
  //   filterDropdown,
  //   onFilterDropdownOpenChange
  // }
}

export function useGetColumnRangePickerProps<RecordType extends Record<string, any>> () {
  const filterIcon: ColumnType<RecordType>['filterIcon'] = <SearchOutlined/>

  const filterDropdown: ColumnType<RecordType>['filterDropdown'] = (props) => {
    const { selectedKeys, setSelectedKeys, confirm, clearFilters } = props

    function handelClear () {
      clearFilters && clearFilters()
      confirm()
    }

    const presets: RangePickerProps['presets'] = [
      {
        label: '今日',
        value: [moment(), moment()]
      },
      {
        label: '昨日',
        value: [
          moment().subtract(1, 'day'),
          moment().subtract(1, 'day')
        ]
      },
      {
        label: '当周',
        value: [
          moment().startOf('week'),
          moment()
        ]
      },
      {
        label: '上一周',
        value: [
          moment().subtract(1, 'week').startOf('week'),
          moment().subtract(1, 'week').endOf('week')
        ]
      },
      {
        label: '当月',
        value: [
          moment().startOf('month'),
          moment()
        ]
      },
      {
        label: '上一月',
        value: [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')
        ]
      }
    ]

    const disabledDate: RangePickerProps['disabledDate'] = current => {
      return current && current > moment().endOf('day')
    }

    const valueOnChange: RangePickerProps['onChange'] = (v) => {
      if (v && v.length) {
        v[0] = v[0]!.startOf('day')
        v[1] = v[1]!.endOf('day')
      }
      // @ts-ignore
      setSelectedKeys(v ? [v] : [])
    }

    return (
      <div style={{ padding: searchSpace }}>
        <DatePicker.RangePicker
          // @ts-ignore
          value={selectedKeys[0]}
          presets={presets}
          allowClear={false}
          disabledDate={disabledDate}
          inputReadOnly
          onChange={valueOnChange}/>
        <Row style={{ marginTop: searchSpace }} gutter={searchSpace}>
          <Col span={12}>
            <Button
              size="small"
              block
              onClick={handelClear}>
              重置
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              size="small"
              icon={<SearchOutlined/>}
              block
              onClick={() => confirm()}>
              搜索
            </Button>
          </Col>
          {/* <Col span={6}> */}
          {/*   <Button */}
          {/*     type="link" */}
          {/*     size="small" */}
          {/*     block */}
          {/*     onClick={() => confirm({ closeDropdown: false })}> */}
          {/*     筛选 */}
          {/*   </Button> */}
          {/* </Col> */}
        </Row>
      </div>
    )
  }

  return useMemo(() => ({
    filterIcon,
    filterDropdown
  }), [])
}

export function useGetColumnCountryProps<RecordType extends Record<string, any>> () {
  const country = useCountrySelector()
  const filterIcon: ColumnType<RecordType>['filterIcon'] = <SearchOutlined/>

  const filterDropdown: ColumnType<RecordType>['filterDropdown'] = (props) => {
    const { selectedKeys, setSelectedKeys, confirm, clearFilters } = props

    function handelClear () {
      clearFilters && clearFilters()
      confirm()
    }

    return (
      <div style={{ padding: searchSpace }}>
        <Select
          value={selectedKeys[0]}
          style={{ width: 200 }}
          options={country}
          showSearch
          fieldNames={{ label: 'c', value: 'i' }}
          filterOption={(v, o) => (o!.c).includes(v)}
          placeholder="请选择"
          onChange={v => setSelectedKeys(v ? [v] : [])}/>
        <Row style={{ marginTop: searchSpace }} gutter={searchSpace}>
          <Col span={12}>
            <Button
              size="small"
              block
              onClick={handelClear}>
              重置
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              size="small"
              icon={<SearchOutlined/>}
              block
              onClick={() => confirm()}>
              搜索
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
  return useMemo(() => ({
    filterIcon,
    filterDropdown
  }), [country])
  // return {
  //   filterIcon,
  //   filterDropdown
  // }
}

export function useFilteredNotShowMenu () {
  const menus = useSelector(getMenusSelector)

  function removeNotShowMenu (_menus: typeof menus = JSON.parse(JSON.stringify(menus))) {
    return _menus.filter(item => {
      item.children = removeNotShowMenu(item.children)
      return item.enable
    })
  }

  return useMemo(removeNotShowMenu, [menus])
}

export function useMenuToTreeSelectData<T extends Record<string, any>> (menus: T[], disabledId?: string) {
  function recursion (_menus: T[] = menus, disabled?: boolean): Required<TreeSelectProps>['treeData'] {
    return _menus.map(item => {
      const flag = !!disabled || (disabledId === item._id)
      return {
        icon: <Icon iconId={item.icon}/>,
        title: item.name,
        value: item._id,
        disabled: flag,
        children: recursion(item.children, flag)
      }
    })
  }

  return useMemo(recursion, [menus, disabledId])
}

export function useEmailAutoComplete (): [Record<string, string>[], (v: string) => void] {
  const [emailResult, setEmailResult] = useState<Record<string, string>[]>([])

  function handleSearch (value: string) {
    let emailResult: Record<string, string>[] = []
    const emailDomain = ['qq.com', '126.com', '163.com', 'gmail.com', 'outlook.com']
    if (value && !~value.indexOf('@')) {
      emailResult = emailDomain.map(domain => {
        return {
          label: `${value}@${domain}`,
          value: `${value}@${domain}`
        }
      })
    }
    setEmailResult(emailResult)
  }

  return [emailResult, handleSearch]
}

export function useHasActions (keys: string[]) {
  const auths = useAuthsSelector()

  return useMemo(() => auths.filter(i => keys.includes(i)).length, [keys, auths])
}

export function createActions (actions: Record<string, ReactNode>, auths: string[]) {
  const keys = Object.keys(actions)
  const actionsList: React.ReactNode[] = []

  keys.forEach(key => {
    if (auths.includes(key) && actions[key]) {
      actionsList.push(actions[key])
      if (actionsList.length % 2) {
        actionsList.push(<Divider type="vertical"/>)
      }
    }
  })

  actionsList.pop()
  return React.Children.toArray(actionsList)
}

export function renderActions (actions: Array<ReactNode>) {
  const elements: typeof actions = []
  actions = actions.filter(Boolean)
  actions.forEach(item => {
    elements.push(item, <Divider type="vertical"/>)
  })
  elements.pop()
  return React.Children.toArray(elements)
}

export const RenderEllipsisText: FC<{ title: ReactNode, children?: ReactNode }> = (props) => {
  const { title, children } = props
  return (
    <Tooltip title={title} placement="topLeft">
      <div className="ant-table-cell-ellipsis">
        {children || title}
      </div>
    </Tooltip>
  )
}

export const RenderTelegramName: FC<{ value?: TelegramInfo }> = ({ value }) => {
  if (!value) return null
  const { id, phone, title, first_name: firstName, last_name: lastName, username } = value
  const name = title || `${firstName || '账号已注销'}${lastName ? ` ${lastName}` : ''}`
  const text = (
    <div style={{ whiteSpace: 'pre-line' }}>
      <p className={'no-margin'}>{id}</p>
      <p className={'no-margin'}>{name}</p>
      <p className={'no-margin'}>{value.username}</p>
      <p className={'no-margin'}>
        {value.premium ? <span style={{ color: '#52c41a' }}>{phone}</span> : phone}
      </p>
      {/* {[id, name, value.username, phone].filter(Boolean).join('\n')} */}
    </div>
  )
  return <RenderEllipsisText title={text}>
    {
      username
        ? <a href={'https://t.me/' + username} target={'tglink'}>
          {name}
        </a>
        : <span>{name}</span>
    }
  </RenderEllipsisText>
}

/**
 * @description 获取ant-table滚动高度
 * @param {Number} length 列表数据
 * @param {Number} pagination 默认56 分页容器高度
 * @param {boolean} mobileAlso
 * @return {Number}
 * */
export function useGetTableScrollY (length: number, pagination?: number, mobileAlso?: boolean) {
  const [scrollY, setScrollY] = useState<number>()
  const [installed, setInstalled] = useState(false)

  useLayoutEffect(() => {
    if (!installed && length) {
      setInstalled(true)
      setScrollY(getTableScrollY(pagination, mobileAlso))
    }
  }, [installed, length, mobileAlso, pagination])

  return scrollY
}

export function useGetTableScrollY1 (length: number, pagination?: number, mobileAlso?: boolean) {
  const [scrollY, setScrollY] = useState<number>()
  const [installed, setInstalled] = useState(false)

  useMemo(() => {
    if (!installed && length) {
      setInstalled(true)
      setScrollY(getTableScrollY(pagination, mobileAlso))
    }
  }, [installed, length, mobileAlso, pagination])

  return scrollY
}

/**
 * @description 获取表格列总宽度
 * @param {Array} columns 表格列
 * @param {Number} initialValue 初始化值，比如要额外添加或减少的宽度
 * @return {Number}
 * */
export function useGetTableScrollX (columns: Columns[], initialValue?: number) {
  return useMemo(() => getTableScrollX(columns, initialValue), [columns, initialValue])
}

/**
 * @description 获取ant-table滚动高度
 * @param {number} pagination 分页高度
 * @return {number}
 * */
export function useTetTableScrollY (pagination = 56) {
  let padding = 0
  const pageHeader = document.querySelector('#layout_header')?.clientHeight || 0
  const pageContent = document.querySelector('#layout_content')
  const commonHeader = document.querySelector('.common__header')?.clientHeight || 0
  const tableThead = document.querySelector('.ant-table-thead')?.clientHeight || 0
  const tableFooter = document.querySelector('.ant-table-footer')?.clientHeight || 0
  const tableSummary = document.querySelector('.ant-table-summary')?.clientHeight || 0
  console.log(pageContent?.scrollHeight)
  console.log(pageContent?.clientHeight)
  const bodyHeight = document.body.clientHeight
  const pageHeaderHeight = Number(pageHeader)
  const commonHeaderHeight = Number(commonHeader) - (commonHeader ? 8 : 0)
  const tableTheadHeight = Number(tableThead) + 1

  if (pageContent) {
    const paddingTop = window.getComputedStyle(pageContent).paddingTop.replace(/\D/g, '')
    padding = Number(paddingTop) * 2
  }

  if (getPlatform() === 'pc') {
    return bodyHeight - pageHeaderHeight - padding - commonHeaderHeight - tableTheadHeight - tableFooter - pagination - tableSummary
  } else {
    const table: HTMLDivElement | null = document.querySelector('.ant-table')
    const maxHeight = bodyHeight - padding - pageHeaderHeight - commonHeaderHeight - pagination
    table && (table.style.cssText = `position:relative;z-index:1;overflow-y:auto;max-height:${maxHeight}px;`)
  }
}

// ios h5需要双击才能清除，添加button包裹解决
export const SelectClearButton = () => {
  return <button className={'select-clear-button'}><CloseCircleFilled/></button>
}
