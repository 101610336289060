import React, { useEffect } from 'react'
import { useAppDispatch } from '@/store'
import { clearUser, setUser } from '@/store/slice/user'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import PageLoading from '@/components/PageLoading'
import * as api from './api'

const LoginByTG: React.FC = () => {
  const { '*': path } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(login, [dispatch, navigate, path])

  function login () {
    const search = location.search
    const params = {
      search
    }

    if (!/hash=.+/.test(search)) return navigate('/user/login', { replace: true })

    api.loginByBot(params).then(({ success, data }) => {
      if (success) {
        dispatch(setUser(data))
        navigate(path ? `/${path}` : '/user/login', { replace: true })
      } else {
        dispatch(clearUser())
        navigate('/user/login', { replace: true })
      }
    })
  }

  return <PageLoading center/>
}

export default LoginByTG
