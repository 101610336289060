import { RootState } from '@/store'
import { createSlice, PayloadAction, createSelector, Dispatch } from '@reduxjs/toolkit'
import { ListItem } from '@/views/payments'
import { enableList } from '@/views/payments/api'

const name = 'payment'
const initialState: ListItem[] = []

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setPaymentList (state, action: PayloadAction<ListItem[]>) {
      return action.payload
    }
  }
})

const { setPaymentList } = slice.actions

// 异步Action
export function getPaymentList () {
  return function (dispatch: Dispatch) {
    return enableList<ListItem[]>().then(({ data, success }) => {
      if (success) {
        dispatch(setPaymentList(data))
      }
    })
  }
}

export const getPayments = (state: RootState) => state[name]
export const getPaymentsByCode = (state: RootState, code?: string) => state[name].filter(i => i.code === code)
export const getPaymentsByCodes = (state: RootState, codes: string[]) => state[name].filter(i => codes.includes(i.code))

export const getInflowPaymentsSelector = createSelector(
  getPayments,
  state => state.filter(i => i.code === 'inflow')
)
export const getBalancePaymentsSelector = createSelector(
  getPayments,
  state => state.filter(i => i.type === 'balance')
)
export const getPaymentsByCodeWithBalanceSelector = createSelector(
  [getPaymentsByCode, getBalancePaymentsSelector],
  (payments, balance) => balance.concat(payments)
)

export default slice
