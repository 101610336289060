import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ListItem } from '@/views/telegram/channel'
import { RootState } from '@/store'

const name = 'channel'

const initialState: ListItem[] = []

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setChannel (state, action: PayloadAction<ListItem[]>) {
      return action.payload
    }
  }
})

// 同步Action
export const { setChannel } = slice.actions

export const getChannel = (state: RootState) => state[name]

export default slice
