import React, { Dispatch, memo, SetStateAction } from 'react'
import classNames from 'classnames/bind'
import { Drawer } from 'antd'
import { LayoutConfig } from '@/components/Layout'
import Title from '@/components/Layout/Title'
import Menu from '@/components/Layout/Menu'
import scss from './index.module.scss'

const cn = classNames.bind(scss)

interface Props {
  open: boolean
  layout: LayoutConfig
  setOpen: Dispatch<SetStateAction<boolean>>
}

const MenuDrawer: React.FC<Props> = (props) => {
  const { open = false, layout, setOpen } = props

  function onClose () {
    setOpen(false)
  }

  return (
    <Drawer
      open={open}
      width={220}
      closable={false}
      placement="left"
      forceRender
      className={scss['menu-drawer']}
      onClose={onClose}>
      <aside style={{ position: 'initial' }} className={cn(scss.aside, layout.siderTheme)}>
        <Title
          theme={layout.siderTheme}
          collapsed={layout.collapsed}/>
        <Menu
          mode={'inline'}
          theme={layout.siderTheme}
          collapsed={false}
          closeDrawer={onClose}/>
      </aside>
    </Drawer>
  )
}

export default memo(MenuDrawer, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
