import { RootState } from '@/store'
import { createSlice, PayloadAction, createSelector, Dispatch } from '@reduxjs/toolkit'
import * as api from '@/views/rate/api'

export interface Rate {
  key: string
  value: number
}

const name = 'rate'

const initialState: Rate[] = []

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setRate (state, action: PayloadAction<Rate[]>) {
      return action.payload
    }
  }
})

// 同步Action
export const { setRate } = slice.actions

// 异步Action
export function getRate () {
  return function (dispatch: Dispatch) {
    return api.getRateLatest().then(({ success, data }) => {
      if (success) {
        data = Object.keys(data).map(key => {
          return {
            key,
            value: data[key]
          }
        })
        dispatch(setRate(data || []))
      }
    })
  }
}

const usdt = {
  key: 'USDT',
  value: 1
}

// 选择器
export const getRateSelector = (state: RootState) => state[name]
export const getRateWithUSDTSelector = createSelector(getRateSelector, state => [usdt, ...state])
export default slice
