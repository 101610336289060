import { ComponentType, lazy, LazyExoticComponent } from 'react'
import { matchPath } from 'react-router-dom'
import Layout from '@/components/Layout'
import LoginByTG from '@/views/user/loginByTG'

const Page404 = lazy(() => import('@/components/Page404'))

interface RouteParam {
  path: string
  element: null | ComponentType | LazyExoticComponent<ComponentType>
  children?: RouteParam[]
  redirect?: string // 重定向路径
  roleAuth?: true // 菜单是否需要验证角色权限
  /**
   * 进入页面前是否需要验证登录状态，父级路由的loginAuth具有优先级
   * true：需要登录（未登录跳转 登录页）
   * false：不需要登录（已登录跳转 首页）
   * undefined 登录和未登录皆可进入页面
   * */
  loginAuth?: boolean
}

const routes: RouteParam[] = [
  {
    path: '/',
    loginAuth: true,
    element: Layout,
    children: [
      {
        path: '/',
        roleAuth: true,
        element: lazy(() => import('@/views/home'))
      },
      {
        path: '/menus',
        roleAuth: true,
        element: lazy(() => import('@/views/menus'))
      },
      {
        path: '/auths/*',
        roleAuth: true,
        element: lazy(() => import('@/views/auths'))
      },
      {
        path: '/roles',
        roleAuth: true,
        element: lazy(() => import('@/views/roles'))
      },
      {
        path: '/logs',
        roleAuth: true,
        element: lazy(() => import('@/views/logs'))
      },
      {
        path: '/icons',
        element: lazy(() => import('@/views/icons'))
      },
      {
        path: '/payments',
        roleAuth: true,
        element: lazy(() => import('@/views/payments'))
      },
      {
        path: '/statistic',
        roleAuth: true,
        element: lazy(() => import('@/views/statistic'))
      },
      {
        path: '/users/*',
        roleAuth: true,
        element: lazy(() => import('@/views/users'))
      },
      {
        path: '/myUsers/*',
        roleAuth: true,
        element: lazy(() => import('@/views/users'))
      },
      {
        path: '/myCenter',
        roleAuth: true,
        element: lazy(() => import('@/views/my_center'))
      },
      {
        path: '/bills/*',
        roleAuth: true,
        element: lazy(() => import('@/views/bills'))
      },
      {
        path: '/rate',
        roleAuth: true,
        element: lazy(() => import('@/views/rate'))
      },
      {
        path: '/help',
        element: lazy(() => import('@/views/help'))
      },
      {
        path: '/price',
        element: lazy(() => import('@/views/help/price'))
      },
      {
        path: '/pay/:type',
        element: lazy(() => import('@/views/pay'))
      },
      {
        path: '/tron',
        roleAuth: true,
        element: lazy(() => import('@/views/tron'))
      },
      {
        path: '/servers',
        roleAuth: true,
        element: lazy(() => import('@/views/servers'))
      },
      {
        path: '/proxy_servers',
        roleAuth: true,
        element: lazy(() => import('@/views/proxy_servers'))
      },
      {
        path: '/telegram',
        roleAuth: true,
        element: lazy(() => import('@/views/telegram/account'))
      },
      {
        path: '/telegrams',
        roleAuth: true,
        element: lazy(() => import('@/views/telegram/account'))
      },
      {
        path: '/mutualUser',
        roleAuth: true,
        element: lazy(() => import('@/views/mutual_user'))
      },
      {
        path: '/channel',
        roleAuth: true,
        element: lazy(() => import('@/views/telegram/channel'))
      },
      {
        path: '/contact',
        roleAuth: true,
        element: lazy(() => import('@/views/telegram/contact'))
      },
      {
        path: '/participant',
        roleAuth: true,
        element: lazy(() => import('@/views/telegram/participant'))
      },
      {
        path: '/message',
        roleAuth: true,
        element: lazy(() => import('@/views/telegram/message'))
      },
      {
        path: '/search',
        roleAuth: true,
        element: lazy(() => import('@/views/telegram/search'))
      },
      {
        path: '/shear',
        roleAuth: true,
        element: lazy(() => import('@/views/telegram/channel_shear'))
      },
      {
        path: '/task',
        roleAuth: true,
        element: lazy(() => import('@/views/task'))
      },
      {
        path: '/taskRecord',
        element: lazy(() => import('@/views/task/record'))
      },
      {
        path: '/location',
        element: lazy(() => import('@/views/telegram/location'))
      },
      {
        path: '/areaCode',
        element: lazy(() => import('@/views/area_code'))
      },
      {
        path: '/folder',
        roleAuth: true,
        element: lazy(() => import('src/views/telegram/folder'))
      }
    ]
  },
  {
    path: '/login/*',
    element: LoginByTG
  },
  {
    path: '/user',
    loginAuth: false,
    element: lazy(/* webpackChunkName: "user" */() => import('@/views/user')),
    children: [
      {
        path: '/user',
        element: null,
        loginAuth: false,
        redirect: '/user/login'
      },
      {
        path: '/user/login',
        loginAuth: false,
        element: lazy(() => import(/* webpackChunkName: "user" */'@/views/user/login'))
      },
      {
        path: '/user/register',
        loginAuth: false,
        element: lazy(() => import(/* webpackChunkName: "user" */'@/views/user/register'))
      },
      {
        path: '/user/retrievePassword',
        loginAuth: false,
        element: lazy(() => import(/* webpackChunkName: "user" */'@/views/user/retrievePassword'))
      }
    ]
  }
  // {
  //   path: '/test',
  //   element: lazy(/* webpackChunkName: "user" */() => import('@/views/test'))
  // }
]
routes[0].children!.push({
  path: '*',
  // redirect: '/',
  element: Page404
})

export function findRouteByPath (path: string, _routes: RouteParam[] = routes): RouteParam | void {
  for (let i = 0, item; (item = _routes[i]); i++) {
    if (matchPath(item.path, path)) {
      return item
    } else {
      item = findRouteByPath(path, item.children || [])
    }
    if (item) return item
  }
}

export function findRoutePathByPath (path: string) {
  return findRouteByPath(path, routes)?.path
}

export type { RouteParam }
export default routes
