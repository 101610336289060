import React from 'react'
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import { ReactComponent as telegramSvg } from '@/assets/icon/telegram.svg'
import { ReactComponent as logoGoogleSvg } from '@/assets/icon/logo_google.svg'
import { ReactComponent as logoTelegramSvg } from '@/assets/icon/logo_telegram.svg'

export const Telegram = (props: Partial<CustomIconComponentProps>) => (
  <Icon {...props} component={telegramSvg}/>
)

export const LogoGoogle = (props: Partial<CustomIconComponentProps>) => (
  <Icon {...props} component={logoGoogleSvg}/>
)

export const LogoTelegram = (props: Partial<CustomIconComponentProps>) => (
  <Icon {...props} component={logoTelegramSvg}/>
)
