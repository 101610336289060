import React, { memo } from 'react'
import { Select, SelectProps } from 'antd'
import { getActiveId, getOptions, Options, setActiveId } from '@/store/slice/account'
import { useDispatch, useSelector } from 'react-redux'

const Index: React.FC = () => {
  const dispatch = useDispatch()
  const options = useSelector(getOptions)
  const activeId = useSelector(getActiveId)

  const filterOption: SelectProps<string, Options>['filterOption'] = (inputValue, option) => {
    return option!.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      new RegExp(inputValue).test(option!.phone)
  }

  return (
    <Select
      value={activeId}
      style={{ width: 160 }}
      showSearch
      placeholder={'请选择账号'}
      filterOption={filterOption}
      dropdownMatchSelectWidth={300}
      onChange={value => dispatch(setActiveId(value))}>
      {
        options.map(item => (
          <Select.Option
            key={item.value}
            value={item.value}
            phone={item.phone}
            label={item.label}
            title={item.phone}>
            <div>
              <span>{item.label}&nbsp;</span>
              <span style={{ fontSize: 12, opacity: 0.5 }}>
                {item.chatCount}群组&nbsp;
                {item.contactCount}好友&nbsp;
                {item.proxyId}
              </span>
            </div>
          </Select.Option>
        ))
      }
    </Select>
  )
}

export default memo(Index)
