import React, { Component, PropsWithChildren } from 'react'
import { Button, Result } from 'antd'

interface Props {
  setKey?: React.Dispatch<React.SetStateAction<number>>
}

interface ErrorProps extends Props {
  error: Error | null
}

const Error: React.FC<ErrorProps> = (props) => {
  const { error, setKey } = props

  function reload () {
    setKey ? setKey(Date.now()) : window.location.reload()
  }

  return (
    <Result
      style={{ background: '#fff' }}
      status="error"
      title="系统错误"
      subTitle="系统运行错误，请尝试刷新页面，或者联系客服处理"
      extra={[
        <Button key={'back'} type="primary" onClick={reload}>
          刷新页面
        </Button>,
        <Button key={'service'} onClick={() => window.open('https://t.me/Y8Net')}>联系客服</Button>
      ]}>
      <pre>
        {error?.message}
        <br/>
        {error?.stack}
      </pre>
    </Result>
  )
}

class ErrorBoundary extends Component<PropsWithChildren<Props>, { error: Error | null }> {
  constructor (props: Props) {
    super(props)
    this.state = {
      error: null
    }
  }

  static getDerivedStateFromError (error: Error) {
    return { error }
  }

  render () {
    return this.state.error
      ? <Error error={this.state.error} setKey={this.props.setKey}/>
      : this.props.children
  }
}

export default ErrorBoundary
