import React, { Dispatch, SetStateAction, useCallback } from 'react'
import classname from 'classnames/bind'
import { Tooltip } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ReloadOutlined
  // SettingOutlined
} from '@ant-design/icons'
import { LayoutConfig } from '@/components/Layout'
import { getPlatform, throttle } from '@/utils'
import Menu from '@/components/Layout/Menu'
import Title from '@/components/Layout/Title'
import ContactUs from '@/components/Layout/Header/ContactUs'
import UserDropdown from '@/components/Layout/Header/UserDropdown'
import scss from '../index.module.scss'
import AccountSelect from '@/views/telegram/AccountSelect'

const cn = classname.bind(scss)

interface Props {
  layout: LayoutConfig
  setKey: Dispatch<SetStateAction<number>>
  setLayout: Dispatch<SetStateAction<LayoutConfig>>
  openMenuDrawer: boolean
  setOpenMenuDrawer: Dispatch<SetStateAction<boolean>>
  setOpenSettingDrawer: Dispatch<SetStateAction<boolean>>
}

const Header: React.FC<Props> = (props) => {
  const { setKey, layout, setLayout, openMenuDrawer, setOpenSettingDrawer, setOpenMenuDrawer } = props
  const trigger = getPlatform() === 'pc' ? 'hover' : 'focus'

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _throttle = useCallback(throttle(reLoad, 2000), [])

  function reLoad () {
    setKey(Date.now())
  }

  return (
    <div className={cn(scss.header, layout.headerTheme)}>
      <div className={scss.header__left}>
        {
          layout.menuLocation === 'top' &&
          <Title width={220} collapsed={false} theme={layout.headerTheme}/>
        }
        {
          layout.menuLocation === 'left' &&
          <div className={scss.header__action} onClick={() => setLayout(s => ({ ...s, collapsed: !s.collapsed }))}>
            {layout.collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
          </div>
        }
        {
          layout.menuLocation === 'drawer' &&
          <div className={scss.header__action} onClick={() => setOpenMenuDrawer(s => !s)}>
            {openMenuDrawer ? <MenuFoldOutlined/> : <MenuUnfoldOutlined/>}
          </div>
        }
      </div>
      <div className={scss.header__center}>
        {
          layout.menuLocation === 'top' &&
          <Menu mode={'horizontal'} theme={layout.headerTheme} collapsed={true}/>
        }
      </div>
      <div className={scss.header__right}>
        <div id={'tour3'} className={scss.header__action}>
          <AccountSelect/>
        </div>
        <Tooltip title="刷新页面" trigger={trigger} placement="bottom">
          <div onClick={_throttle} className={scss.header__action}>
            <ReloadOutlined/>
          </div>
        </Tooltip>
        {/* <Tooltip title='布局设置' trigger={trigger} placement="bottom"> */}
        {/*   <div onClick={() => setOpenSettingDrawer(true)} className={scss.header__action}> */}
        {/*     <SettingOutlined/> */}
        {/*   </div> */}
        {/* </Tooltip> */}
        <ContactUs className={scss.header__action}/>
        <UserDropdown
          className={scss.header__action}
          setOpenSettingDrawer={setOpenSettingDrawer}/>
      </div>
    </div>
  )
}

export default Header
