import React, { useEffect, useState } from 'react'
import { Tabs, Dropdown, Button, MenuProps, TabsProps } from 'antd'
import {
  TagOutlined,
  MenuOutlined,
  ArrowRightOutlined,
  // PoweroffOutlined,
  ArrowLeftOutlined,
  AimOutlined
} from '@ant-design/icons'
import { useFilteredNotShowMenu } from '@/utils/hooks'
import { useLocation } from 'react-router-dom'
import { findMenuByKey } from '@/utils'
import { useNavigate } from 'react-router'
import { findRoutePathByPath } from '@/router/routes'
import Icon from '@/components/Icon'
import scss from './index.module.scss'

const Index: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [tabs, setTabs] = useState<Required<TabsProps>['items']>([])
  const [activeKey, setActiveKey] = useState<string>()
  const filteredNotShowMenu = useFilteredNotShowMenu()

  const menuItems: MenuProps['items'] = [
    {
      key: 'self',
      icon: <AimOutlined/>,
      label: '关闭当前'
    },
    {
      key: 'other',
      icon: <TagOutlined/>,
      label: '关闭其他'
    },
    {
      key: 'left',
      icon: <ArrowLeftOutlined/>,
      label: '关闭左侧'
    },
    {
      key: 'right',
      icon: <ArrowRightOutlined/>,
      label: '关闭右侧'
    }
    // {
    //   key: 'all',
    //   icon: <PoweroffOutlined/>,
    //   label: '关闭全部'
    // }
  ]

  useEffect(getActiveKey, [location, filteredNotShowMenu])

  function getActiveKey () {
    const path = findRoutePathByPath(location.pathname)
    const menu = findMenuByKey(filteredNotShowMenu, 'path', path)
    if (menu) {
      setTabs(tabs => {
        const isExist = tabs.some(item => item.key === menu._id)
        if (isExist) {
          return tabs
        } else {
          return [...tabs, {
            key: menu._id,
            label: <a href={location.pathname} onClick={e => e.preventDefault()}>
              <Icon style={{ marginRight: 6 }} iconId={menu.icon}/>
              <span>{menu.name}</span>
            </a>
          }]
        }
      })
      setActiveKey(menu._id)
    } else {
      setActiveKey('')
    }
  }

  const menuOnClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'self':
        return closeSelf()
      case 'other':
        return closeOther()
      case 'left':
        return closeLeft()
      case 'right':
        return closeRight()
      case 'all':
        return closeAll()
    }

    function closeSelf () {
      if (activeKey && tabs.length > 1) {
        const index = tabs.findIndex(item => item.key === activeKey)
        const preTab = tabs[index - 1]
        const nextTab = tabs[index + 1] || preTab
        const menu = findMenuByKey(filteredNotShowMenu, '_id', nextTab.key)
        menu && navigate(menu.path as string)
        setTabs(tabs => tabs.filter(item => item.key !== activeKey))
      } else {
        closeAll()
      }
    }

    function closeOther () {
      setTabs(tabs => tabs.filter(item => item.key === activeKey))
    }

    function closeLeft () {
      const index = tabs.findIndex(item => item.key === activeKey)
      setTabs(tabs => tabs.slice(index))
    }

    function closeRight () {
      const index = tabs.findIndex(item => item.key === activeKey)
      setTabs(tabs => tabs.slice(0, index + 1))
    }

    function closeAll () {
      setTabs([])
      navigate('/')
    }
  }

  const onTabClick: TabsProps['onTabClick'] = (activeKey) => {
    const menu = findMenuByKey(filteredNotShowMenu, '_id', activeKey)
    menu && navigate(menu.path as string)
  }

  const TabDropdown = (
    <Dropdown
      menu={{ items: menuItems, onClick: menuOnClick }}
      placement="bottomRight">
      <Button type="text" icon={<MenuOutlined/>}/>
    </Dropdown>
  )

  return (
    tabs.length
      ? <Tabs
        size={'small'}
        items={tabs}
        activeKey={activeKey}
        className={scss.tabs}
        tabBarExtraContent={TabDropdown}
        onTabClick={onTabClick}/>
      : null
  )
}

export default Index
