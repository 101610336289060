const errorMap: Record<string, string> = {
  // 303 SEE_OTHER
  FILE_MIGRATE_X: '要访问的文件当前存储在不同的数据中心',
  PHONE_MIGRATE_X: '用户尝试用于授权的手机号码与不同的数据中心相关联',
  NETWORK_MIGRATE_X: '源IP地址与不同的数据中心相关联', // （用于注册）
  USER_MIGRATE_X: '其身份被用于执行查询的用户与不同的数据中心相关联',
  // 400 BAD_REQUEST
  AUTH_RESTART: '重新启动授权过程',
  PHONE_NUMBER_BANNED: '手机号码已被禁止',
  PHONE_NUMBER_INVALID: '手机号码无效',
  PHONE_CODE_INVALID: '验证码无效',
  PHONE_CODE_EXPIRED: '验证码已过期',
  PHONE_NUMBER_OCCUPIED: '手机号码已被使用',
  PHONE_NUMBER_UNOCCUPIED: '手机号码尚未被使用',
  FIRSTNAME_INVALID: '名字无效',
  LASTNAME_INVALID: '姓氏无效',
  CONNECTION_NOT_INITED: '连接未初始化',
  // 发言
  PEER_FLOOD: '账号目前受到限制',
  CHANNEL_INVALID: '群组无效',
  CHANNEL_PRIVATE: '不可访问',
  CHAT_RESTRICTED: '被禁言',
  CHAT_WRITE_FORBIDDEN: '被禁言',
  USER_BANNED_IN_CHANNEL: '被禁止在群组中发送消息',
  SLOWMODE_WAIT_X: '慢速模式 X 秒',
  CHAT_SEND_PLAIN_FORBIDDEN: '禁止发送文本消息',
  INPUT_USER_DEACTIVATED: '用户已注销',
  // 加群
  CHAT_INVALID: '聊天无效',
  USERNAME_INVALID: '用户名无效',
  USERNAME_NOT_OCCUPIED: '用户名不存在',
  INVITE_HASH_EXPIRED: '邀请链接已过期',
  USER_ALREADY_PARTICIPANT: '已加入群组',
  INVITE_REQUEST_SENT: '已提交加群请求',
  // 查找附近
  GEO_POINT_INVALID: '提供的地理位置无效',
  USERPIC_UPLOAD_REQUIRED: '您必须有个人资料照片才能发布您的地理位置',
  USERPIC_PRIVACY_REQUIRED: '您需要禁用个人资料图片的隐私设置才能公开您的地理位置',
  // 重置密码
  PASSWORD_EMPTY: '密码为空',
  PASSWORD_HASH_INVALID: '密码不正确',
  // 401 UNAUTHORIZED
  AUTH_KEY_UNREGISTERED: '密钥未在系统中注册',
  AUTH_KEY_INVALID: '密钥无效',
  USER_DEACTIVATED: '账号已被注销',
  USER_DEACTIVATED_BAN: '账号已被封禁',
  SESSION_REVOKED: '会话已失效',
  SESSION_EXPIRED: '会话已过期',
  AUTH_KEY_PERM_EMPTY: '该方法对临时授权密钥不可用，未绑定永久',
  Offline: '账号已下线',
  // 406 NOT_ACCEPTABLE
  AUTH_KEY_DUPLICATED: '会话已经被服务器作废',
  // 420 FLOOD
  FLOOD_WAIT_X: '频率限制 X 秒',
  Timeout: '请求超时',
  FILTER_INCLUDE_EMPTY: '已加入全部群组',
  INVITES_TOO_MUCH: '邀请链接数量已满',
  CHATLISTS_TOO_MUCH: '邀请链接数量已满',
  INVITE_PEERS_TOO_MUCH: '分享群组数量过多',
  FILTER_INCLUDE_TOO_MUCH: '邀请链接群组数量过多',
  INVITE_SLUG_EXPIRED: '聊天文件夹邀请链接已过期',
  API_ID_PUBLISHED_FLOOD: 'API_ID频率限制，请更换其他登录设备'
}

export function translate (message?: string) {
  if (!message) return message
  const key = message.replace(/\d+/, 'X')
  const number = Number(message.match(/\d+/))
  message = errorMap[key]?.replace('X', String(number)) || message
  return message
}
