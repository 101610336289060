import React, { memo, useState } from 'react'
import { Modal, Form, Input } from 'antd'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { isMobile, requiredInputRule } from '@/utils/hooks'
import { Rule } from 'antd/es/form'
import { passwordReg } from '@/utils/validate'
import { clearUser } from '@/store/slice/user'
import { useDispatch } from 'react-redux'
import * as api from '@/views/user/api'

interface Props {
  open: boolean
  onCancel: () => void
}

const UpdatePassword: React.FC<Props> = (props) => {
  const { open = false, onCancel } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  const [form] = Form.useForm()
  const passwordRule: Rule[] = [
    ...requiredInputRule,
    {
      pattern: passwordReg,
      message: '请输入6-20位数字或字母或特殊符号'
    }
  ]
  const newPasswordRule: Rule[] = [
    ...passwordRule,
    {
      validator (rule, value) {
        if (form.getFieldValue('password') === value) {
          return Promise.reject(Error('新密码与旧密码不能相同'))
        }
        return Promise.resolve()
      }
    }
  ]
  const confirmPasswordRule: Rule[] = [
    ...requiredInputRule,
    {
      validator (rule, value) {
        if (form.getFieldValue('newPassword') !== value) {
          return Promise.reject(Error('两次输入的密码不匹配'))
        }
        return Promise.resolve()
      }
    }
  ]

  function onFinish (values: Record<string, any>) {
    values = {
      password: values.password,
      newPassword: values.newPassword
    }
    setLoading(true)
    api.updatePassword(values).then(({ success }) => {
      if (success) {
        onCancel()
        Modal.success({
          title: '修改成功',
          centered: true,
          keyboard: false,
          content: '密码修改成功，请重新登录',
          onOk () {
            dispatch(clearUser())
          }
        })
      }
      setLoading(false)
    })
  }

  return (
    <Modal
      open={open}
      title={'修改密码'}
      className="common-modal"
      centered={isMobile}
      forceRender
      maskClosable={false}
      confirmLoading={loading}
      onOk={() => form.submit()}
      onCancel={onCancel}
      afterClose={form.resetFields}>
      <Form
        name="updatePassword"
        form={form}
        layout="vertical"
        onFinish={onFinish}>
        <Form.Item name="password" label="旧密码" rules={passwordRule} validateFirst>
          <Input.Password
            prefix={<UnlockOutlined/>}
            maxLength={20}
            placeholder="旧密码"
            autoComplete="new-password"/>
        </Form.Item>
        <Form.Item name="newPassword" label="新密码" rules={newPasswordRule} dependencies={['password']} validateFirst>
          <Input.Password
            prefix={<LockOutlined/>}
            maxLength={20}
            placeholder="新密码"
            autoComplete="new-password"/>
        </Form.Item>
        <Form.Item
          name="confirm"
          label="确认密码"
          rules={confirmPasswordRule}
          dependencies={['newPassword']}
          validateFirst>
          <Input.Password
            prefix={<LockOutlined/>}
            placeholder="确认密码"
            visibilityToggle={false}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

// export default UpdatePassword
export default memo(UpdatePassword, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
