import Icon from '@ant-design/icons'
import * as icons from '@ant-design/icons/lib/icons'
import * as customIcons from '@/components/Icon/customIcon'

const _icons: { [key: string]: typeof Icon } = { ...icons }

export const antIcons: { [key: string]: any } = { ...customIcons }
export const antIconKeys: string[] = Object.keys(customIcons)

Object.keys(_icons).forEach(key => {
  if (!/TwoTone$/.test(key)) {
    antIcons[key] = _icons[key]
    antIconKeys.push(key)
  }
})
