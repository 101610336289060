import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const name = 'auth'

const initialState: string[] = []

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAuths (state, action: PayloadAction<string[]>) {
      return [...action.payload]
    }
  }
})

// 同步Action
export const {
  setAuths
} = slice.actions

export const getAuthsSelector = (state: RootState) => state[name]

export default slice
