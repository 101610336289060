import React, { CSSProperties } from 'react'
import { Spin } from 'antd'

interface Props {
  center?: true
}

export default function PageLoading ({ center }: Props) {
  const style: CSSProperties = {
    paddingTop: 100,
    textAlign: 'center'
  }
  const centerStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

  return (
    <div style={center ? centerStyle : style}>
      <Spin size="large"/>
    </div>
  )
}
