import React, { memo, useEffect, useState } from 'react'
import { Modal, Form, Input, AutoComplete, Row, Col, Button, message } from 'antd'
import { isMobile, requiredInputRule, useEmailAutoComplete, useUserSelector } from '@/utils/hooks'
import { Rule } from 'antd/es/form'
import { emailReg } from '@/utils/validate'
import { MailOutlined, SafetyCertificateOutlined } from '@ant-design/icons'
import { setUser } from '@/store/slice/user'
import { useAppDispatch } from '@/store'
import * as api from '@/views/user/api'
import scss from '@/views/user/index.module.scss'

interface Props {
  open: boolean
  onCancel: () => void
}

const Index: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch()
  const { open = false, onCancel } = props
  const [count, setCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [codeLoading, setCodeLoading] = useState<Record<string, boolean>>({})
  const [emailResult, handleSearch] = useEmailAutoComplete()

  const user = useUserSelector()

  const [form] = Form.useForm()
  const emailRule: Rule[] = [
    ...requiredInputRule,
    {
      pattern: emailReg,
      message: '请输入正确的邮箱'
    },
    {
      pattern: new RegExp(`^(?!${user.email}).*$`),
      message: '新邮箱不能和旧邮箱相同'
    }
  ]
  const captchaRule: Rule[] = [
    ...requiredInputRule,
    {
      validator () {
        if (!form.getFieldValue('captchaId')) {
          return Promise.reject(Error('请先获取验证码'))
        }
        return Promise.resolve()
      }
    }
  ]
  const oldCaptchaRule: Rule[] = [
    ...requiredInputRule,
    {
      validator () {
        if (!form.getFieldValue('oldEmailCaptchaId')) {
          return Promise.reject(Error('请先获取验证码'))
        }
        return Promise.resolve()
      }
    }
  ]

  useEffect(countdown, [count])

  function countdown () {
    let timer: NodeJS.Timeout
    if (count <= 0) {
      setCount(0)
    } else {
      timer = setTimeout(() => setCount(count - 1), 1000)
    }

    return () => clearTimeout(timer)
  }

  function getOldEmailCaptcha () {
    const params = {
      email: user.email
    }
    setCodeLoading(s => ({ ...s, old: true }))
    api.getEmailCode(params).then(({ success, data }) => {
      if (success) {
        form.setFieldsValue({ oldEmailCaptchaId: data.captchaId })
        message.success(`验证码已发送到邮箱，${data.exp}分钟内有效，请注意查收`)
      }
    }).finally(() => setCodeLoading(s => ({ ...s, old: false })))
  }

  function getCaptcha () {
    form.validateFields(['email']).then((values) => {
      setCodeLoading(s => ({ ...s, new: true }))
      api.getEmailCode(values).then(({ success, data }) => {
        if (success) {
          setCount(60)
          form.setFieldsValue({ captchaId: data.captchaId })
          message.success(`验证码已发送到邮箱，${data.exp}分钟内有效，请注意查收`)
        }
      }).finally(() => setCodeLoading(s => ({ ...s, new: false })))
    })
  }

  function onFinish (values: Record<string, any>) {
    setLoading(true)
    api.updateEmail(values).then(({ success, data }) => {
      if (success) {
        onCancel()
        dispatch(setUser({ email: data.email }))
        Modal.success({
          title: '修改成功',
          centered: true,
          content: '修改成功，您现在可以使用新邮箱了'
        })
      }
      setLoading(false)
    })
  }

  return (
    <Modal
      open={open}
      title={'修改邮箱'}
      className="common-modal"
      centered={isMobile}
      maskClosable={false}
      confirmLoading={loading}
      onOk={() => form.submit()}
      onCancel={onCancel}
      afterClose={form.resetFields}>
      <Form
        name="updateEmail"
        form={form}
        layout="vertical"
        initialValues={{ oldEmail: user.email }}
        onFinish={onFinish}>
        <Form.Item name="email" label={'新邮箱'} rules={emailRule} validateFirst>
          <AutoComplete options={emailResult} onSearch={handleSearch}>
            <Input prefix={<MailOutlined/>} placeholder="邮箱"/>
          </AutoComplete>
        </Form.Item>
        <Form.Item required label={'验证码'}>
          <Row gutter={4}>
            <Col span={15}>
              <Form.Item name="captchaId" noStyle>
                <Input hidden/>
              </Form.Item>
              <Form.Item name="captcha" dependencies={['captchaId']} rules={captchaRule} noStyle validateFirst>
                <Input pattern="\d*" prefix={<SafetyCertificateOutlined/>} maxLength={6} placeholder="邮箱验证码"/>
              </Form.Item>
            </Col>
            <Col span={9}>
              <Button
                onClick={getCaptcha} disabled={count > 0} loading={codeLoading.new}
                className={scss['register__captcha-button']}>
                {
                  count > 0
                    ? count + ' s'
                    : '发送验证码'
                }
              </Button>
            </Col>
          </Row>
        </Form.Item>
        {
          user.email &&
          <Form.Item required label={'旧邮箱验证码'}>
            <Row gutter={4}>
              <Col span={15}>
                <Form.Item name="oldEmail" noStyle>
                  <Input hidden/>
                </Form.Item>
                <Form.Item name="oldEmailCaptchaId" noStyle>
                  <Input hidden/>
                </Form.Item>
                <Form.Item name="oldEmailCaptcha" dependencies={['oldEmailCaptchaId']} rules={oldCaptchaRule} noStyle
                  validateFirst>
                  <Input pattern="\d*" prefix={<SafetyCertificateOutlined/>} maxLength={6} placeholder="邮箱验证码"/>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Button
                  onClick={getOldEmailCaptcha} loading={codeLoading.old}
                  className={scss['register__captcha-button']}>
                  发送验证码
                </Button>
              </Col>
            </Row>
          </Form.Item>
        }
      </Form>
    </Modal>
  )
}

// export default Index
export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
