import { AppThunk, RootState } from '@/store'
import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit'
import { DefaultOptionType } from 'rc-select/lib/Select'
import * as api from '@/api/common'

export interface Country extends DefaultOptionType {
  i: string // 序号
  c: string // 中文简称
  e: string // 英文简称
  f: string // 国旗emoji
}

const name = 'country'
const initialState: Country[] = []

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setCountry (state, action: PayloadAction<Country[]>) {
      return action.payload
    }
  }
})

// 同步Action
export const {
  setCountry
} = slice.actions

// 异步Action
export function getCountry (): AppThunk<void> {
  return function (dispatch: Dispatch, getState) {
    const store = getState()
    if (store.country.length) return Promise.resolve()
    return api.getCountry<Country[]>().then(({ data, success }) => {
      if (success) {
        dispatch(setCountry(data))
      }
    })
  }
}

export const getCountrySelector = (state: RootState) => state[name]

export default slice
