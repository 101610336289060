import http from '@/api/http'
import store from '@/store'
import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'
import { uploadConfig } from '@/api/common'
import { selectById } from '@/store/slice/account'

interface BatchAction {
  _id: string[]
}

// 多个账号根据serverId向多个服务器发送请求
function requestServers<T> (url: string, params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const ids = params._id
  const map: Record<string, string[]> = {}
  const state = store.getState()
  ids.forEach(item => {
    const serverId = String(selectById(state, item)?.serverId)
    if (map[serverId]) {
      map[serverId].push(item)
    } else {
      map[serverId] = [item]
    }
  })
  const promises = Object.keys(map).map(key => {
    const _params = {
      ...params,
      _id: map[key]
    }
    const _url = '/' + key + url
    return http.post<ResponseData<T>>(_url, _params, config).then(r => r.data)
  })

  return formatServersResult<T>(promises, getSort())

  function getSort () {
    const sort: number[] = []
    const requestId = Object.values(map).flat()
    ids.forEach((id, index) => {
      sort[index] = requestId.findIndex(i => i === id)
    })
    return sort
  }
}

// 格式化多个服务器返回的数据
function formatServersResult<T> (promises: Promise<ResponseData<T>>[], sort: number[]) {
  return Promise.all(promises).then(data => {
    const error = data.find(item => !item.success)
    const dataList: any[] = []

    if (error) return error

    sort.forEach((item, index) => {
      const results = data.map(item => item.data).flat()
      dataList[index] = results[item]
    })

    data[0].data = dataList.flat() as unknown as T
    return data[0]
  })
}

export function updateReply<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/updateReply'
  return requestServers<T>(url, params, config)
}

export function updateKeyword<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/updateKeyword'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新账号状态(上线/下线)
export function updateEnable<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/updateEnable'
  return requestServers<T>(url, params, config)
}

/**
 * 登录前的方法 start
 * */
// 导入协议号
export function importTelegram<T = any> (serverId: string, params: RequestParams, config?: AxiosRequestConfig) {
  const url = `/${serverId}/tg/importTelegram`
  config = Object.assign(uploadConfig, config)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 二维码登录
export function exportLoginToken<T = any> (serverId: string, params: RequestParams, config?: AxiosRequestConfig) {
  const url = `/${serverId}/tg/exportLoginToken`
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 发送验证码
export function sendCode<T = any> (serverId: string, params: RequestParams, config?: AxiosRequestConfig) {
  const url = `/${serverId}/tg/sendCode`
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 登陆
export function signIn<T = any> (serverId: string, params: RequestParams, config?: AxiosRequestConfig) {
  const url = `/${serverId}/tg/signIn`
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 注册
export function signUp<T = any> (serverId: string, params: RequestParams, config?: AxiosRequestConfig) {
  const url = `/${serverId}/tg/signUp`
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 密码登陆
export function signInByPassword<T = any> (serverId: string, params: RequestParams, config?: AxiosRequestConfig) {
  const url = `/${serverId}/tg/signInByPassword`
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

/**
 * 登录前的方法 end
 * */
export function reloadTelegram<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/reloadTelegram'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function reloadTelegrams<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/reloadTelegrams'
  return requestServers<T>(url, params, config)
}

export function reloadTelegramsChannel<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/reloadTelegramsChannel'
  return requestServers<T>(url, params, config)
}

export function getProfilePhoto<T = Uint8Array> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/getProfilePhoto'
  params = {
    ...params,
    _notHandleResponse: true
  }
  config = {
    responseType: 'arraybuffer'
  }
  return http.post<T>(url, params, config).then(r => r.data)
}

export function uploadProfilePhoto<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/uploadProfilePhoto'
  config = Object.assign(uploadConfig, config)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function batchUploadProfilePhoto<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/batchUploadProfilePhoto'
  config = Object.assign(uploadConfig, config)
  return requestServers<T>(url, params, config)
}

export function batchUpdatePasswordSettings<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/batchUpdatePasswordSettings'
  return requestServers<T>(url, params, config)
}

export function resetPassword<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/resetPassword'
  return requestServers<T>(url, params, config)
}

export function getPrivacy<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/getPrivacy'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function batchSetPrivacy<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/batchSetPrivacy'
  return requestServers<T>(url, params, config)
}

export function checkUsername<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/checkUsername'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function updateProfile<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/updateProfile'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function updateUsernameAndProfile<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/updateUsernameAndProfile'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function batchUpdateUsernameAndProfile<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/batchUpdateUsernameAndProfile'
  return requestServers<T>(url, params, config)
}

export function logOut<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/logOut'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function logOuts<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/logOuts'
  return requestServers<T>(url, params, config)
}

export function resetAuthorization<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/resetAuthorization'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function resetAuthorizations<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/resetAuthorizations'
  return requestServers<T>(url, params, config)
}

export function getAllChats<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/getAllChats'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function reloadChats<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/reloadChats'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function getDialogs<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/getDialogs'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function leaveChannels<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/leaveChannels'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function joinChannelsByUsername<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/joinChannelsByUsername'
  return requestServers<T>(url, params, config)
}

export function joinChannels<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/joinChannels'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function importChatInvite<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/importChatInvite'
  return requestServers<T>(url, params, config)
}

export function checkChatInvite<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/checkChatInvite'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function getParticipants<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/getParticipants'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function getContacts<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/getContacts'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function addContactsByUsername<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/addContactsByUsername'
  return requestServers<T>(url, params, config)
}

export function addContacts<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/addContacts'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function importContacts<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/importContacts'
  return requestServers<T>(url, params, config)
}

export function deleteContacts<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/deleteContacts'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function inviteToChannel<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/inviteToChannel'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function getHistory<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/getHistory'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function messagesSearch<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/messagesSearch'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function sendMessage<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/sendMessage'
  params.message = params.message && encodeURIComponent(params.message)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function sendMedia<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/sendMedia'
  params.message = params.message && encodeURIComponent(params.message)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function sendMultiMedia<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/sendMultiMedia'
  params.message = params.message && encodeURIComponent(params.message)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function forwardMessages<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/forwardMessages'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function editMessage<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/editMessage'
  params.message = params.message && encodeURIComponent(params.message)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function resolveUsername<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/resolveUsername'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function resolveChannelUsername<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/resolveChannelUsername'
  return requestServers<T>(url, params, config)
}

export function uploadMedia<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/uploadMedia'
  config = Object.assign(uploadConfig, config)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function getPhotoFile<T = Uint8Array> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/getPhotoFile'
  params = {
    ...params,
    _notHandleResponse: true
  }
  config = {
    responseType: 'arraybuffer'
  }
  return http.post<T>(url, params, config).then(r => r.data)
}

export function search<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/search'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function getLocated<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/getLocated'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function getAuthorizations<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/getAuthorizations'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function updateState<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/updateState'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function exportInvite<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/exportInvite'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function checkInvite<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/tg/checkInvite'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

export function joinInvite<T = any> (params: RequestParams<BatchAction>, config?: AxiosRequestConfig) {
  const url = '/tg/joinInvite'
  return requestServers<T>(url, params, config)
}
