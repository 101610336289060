import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Menu, MenuProps, message } from 'antd'
import { MenuTheme } from 'antd/lib/menu/MenuContext'
import { getMenusSelector, Menu as TMenu } from '@/store/slice/user'
import { useFilteredNotShowMenu } from '@/utils/hooks'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import { findMenuByKey } from '@/utils'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { setAuths } from '@/store/slice/auth'
import { findRoutePathByPath } from '@/router/routes'
import domain from '@/config/domain'
import Icon from '@/components/Icon'
import scss from './index.module.scss'

interface Props {
  mode: 'inline' | 'horizontal'
  theme: MenuTheme
  collapsed: boolean
  closeDrawer?: () => void
}

const Index: React.FC<Props> = (props) => {
  const { mode, theme, collapsed, closeDrawer } = props
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])

  const menus = useSelector(getMenusSelector)
  const filteredNotShowMenus = useFilteredNotShowMenu()
  const menuItems = useMemo(createMenuItems, [createMenuItems, filteredNotShowMenus])

  useEffect(getOpenKeys, [location, filteredNotShowMenus])
  useEffect(setSelectedMenuAuths, [dispatch, location, menus])

  function createMenuItems (menus: TMenu[] = filteredNotShowMenus): ItemType[] {
    return menus.map(item => {
      return {
        id: item.path ? `tour_${item.path}` : undefined,
        key: item._id,
        icon: <Icon iconId={item.icon}/>,
        label: <a href={item.path} onClick={e => e.preventDefault()}>{item.name}</a>,
        children: item.children.length ? createMenuItems(item.children) : undefined,
        onTitleClick
      }
    })
  }

  // 根据path，设置展开菜单和选中菜单
  function getOpenKeys () {
    const path = findRoutePathByPath(location.pathname)
    const menu = findMenuByKey(filteredNotShowMenus, 'path', path)

    if (menu) {
      const openKeys = getParentsId(filteredNotShowMenus, menu.parent)
      setOpenKeys(openKeys)
      setSelectedKeys([menu._id])
    } else {
      setOpenKeys([])
      setSelectedKeys([])
    }

    // 获取菜单的向上所有父节点
    function getParentsId (menus: TMenu[] = [], parentId?: string, keys: string[] = []): string[] {
      if (parentId) {
        const parent = findMenuByKey(menus, '_id', parentId)
        if (parent) {
          keys.push(parent._id)
          getParentsId(menus, parent.parent, keys)
        }
      }
      return keys
    }
  }

  // 使用全部菜单数据，隐藏菜单也需要设置权限
  function setSelectedMenuAuths () {
    const path = findRoutePathByPath(location.pathname)
    const menu = findMenuByKey(menus, 'path', path)
    const auths = menu?.auths || []
    document.title = menu?.name || domain.title
    dispatch(setAuths(auths))
  }

  function onTitleClick ({ key }: Record<string, string>) {
    setOpenKeys(keys => {
      keys = [...keys]
      const isExist = keys.some(item => item === key)
      if (isExist) {
        keys = keys.filter(item => item !== key)
      } else {
        keys.push(key)
      }
      return keys
    })
  }

  const onClick: MenuProps['onClick'] = ({ key }) => {
    const menu = findMenuByKey(filteredNotShowMenus, '_id', key)
    if (menu && menu.path) {
      if (/^\//.test(menu.path)) {
        navigate(menu.path)
        closeDrawer && closeDrawer()
      } else if (/^http?s/.test(menu.path)) {
        window.open(menu.path)
      }
    } else {
      message.info('页面地址不存在')
    }
  }

  return (
    <Menu
      mode={mode}
      theme={theme}
      items={menuItems}
      // openKeys={collapsed ? undefined : openKeys}
      className={scss.menu}
      inlineIndent={16}
      inlineCollapsed={mode === 'inline' ? collapsed : undefined}
      selectedKeys={selectedKeys}
      onClick={onClick}/>
  )
}

export default Index
