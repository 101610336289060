import React, { Suspense, useEffect, useMemo } from 'react'
import { createBrowserHistory } from 'history'
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { getMenusSelector, getTokenSelector } from '@/store/slice/user'
import { findMenuByKey } from '@/utils'
import Page403 from '@/components/Page403'
import PageLoading from '@/components/PageLoading'
import routes, { RouteParam } from './routes'

interface RenderMainProps extends RouteParam {
  child?: boolean
}

const history = createBrowserHistory()

function createMainRoute (routes: RouteParam[], child?: boolean) {
  return routes.map((route, i) => {
    const { path, children } = route

    return (
      <Route key={i} path={path} element={<RenderMain child={child} {...route}/>}>
        {
          children && children.length && createMainRoute(children, true)
        }
      </Route>
    )
  })
}

function RenderMain (props: RenderMainProps) {
  const { child, element: Element, loginAuth, redirect } = props

  const navigate = useNavigate()
  const location = useLocation()
  const loginStatus = !!useSelector(getTokenSelector)

  const { state, search, pathname } = location
  const toHome = loginStatus && loginAuth === false
  const toLogin = !loginStatus && !!loginAuth
  // console.log('｜pathname:', pathname, '｜loginAuth:', loginAuth, '｜toHome:', toHome, '｜toLogin:', toLogin)
  useEffect(() => {
    if (toHome) {
      const from = (state as Record<string, any>)?.from || '/'
      navigate(from, { replace: true })
    } else if (toLogin) {
      const from = pathname + search
      navigate('/user/login', {
        state: { from },
        replace: true
      })
    }
  }, [loginStatus, location])

  if (toHome || toLogin) return null
  if (redirect) return <Navigate to={redirect} replace/>

  return child
    ? <Suspense fallback={<PageLoading/>}>{Element && <Element/>}</Suspense>
    : Element && <Element/>
}

function Router () {
  const menus = useSelector(getMenusSelector)
  const filteredRoutes = useMemo(() => filterRoutes(routes), [menus])

  function filterRoutes (routes: RouteParam[]) {
    return routes.map(({ ...route }) => {
      const hasRoleAuth = !route.roleAuth || !!findMenuByKey(menus, 'path', route.path)
      if (hasRoleAuth) {
        route.children = route.children && filterRoutes(route.children)
      } else {
        route.element = Page403
      }
      return route
    })
  }

  return (
    <HistoryRouter history={history as any}>
      <Suspense fallback={<PageLoading center/>}>
        <Routes>
          {createMainRoute(filteredRoutes)}
        </Routes>
      </Suspense>
    </HistoryRouter>
  )
}

export { history }
export default Router
