'use strict'

const opsConfig = (typeof window === 'object' && window.opsConfig)
const dev = process.env.NODE_ENV === 'development'
const apiUrl = opsConfig?.apiUrl || (dev ? 'http://127.0.0.1:3001' : '/v1')
const socketUrl = dev ? 'http://127.0.0.1:3001' : ''
const cdnServer = dev ? apiUrl : '/public'

const config = {
  apiUrl,
  socketUrl,
  cdnServer,
  photo: cdnServer + '/photo/',
  media: cdnServer + '/media/'
}

module.exports = config
