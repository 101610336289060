import React from 'react'
import { CustomerServiceOutlined, MailOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps } from 'antd'
import { Telegram } from '@/components/Icon/customIcon'
import domain from '@/config/domain'

interface Props {
  className?: string;
}

type MenuItem = Required<MenuProps>['items'][number]

const Index: React.FC<Props> = ({ className }) => {
  const items: MenuItem[] = [
    {
      key: '1',
      icon: <Telegram/>,
      label: <a rel="noreferrer" href={domain.telegram} target="tglink">
        <span>在线客服</span>
      </a>
    },
    {
      key: '2',
      icon: <Telegram/>,
      label: <a rel="noreferrer" href={domain.channel} target="tglink">
        <span>官方群组</span>
      </a>
    },
    domain.mutualBot
      ? {
        key: 'mutualBot',
        icon: <Telegram/>,
        label: <a rel="noreferrer" href={domain.mutualBot} target="tglink">
          <span>双向机器人</span>
        </a>
      }
      : null,
    {
      type: 'divider'
    },
    {
      key: '10',
      icon: <MailOutlined/>,
      label: <a rel="noreferrer" href={domain.email} target="email">
        <span>官方邮箱</span>
      </a>
    }
  ]

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <div id={'tour2'} className={className}>
        <CustomerServiceOutlined/>
      </div>
    </Dropdown>
  )
}

export default Index
