import { AppThunk, RootState } from '@/store'
import { createSlice, Dispatch, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit'
import { ListItem } from '@/views/telegram/account'
import { DefaultOptionType } from 'rc-select/lib/Select'
import { all } from '@/views/telegram/account/api'

const name = 'account'

export interface Options extends DefaultOptionType {
  label: string
  value: string
  phone: ListItem['user']['phone']
  proxyId: ListItem['proxyId']
  chatCount: ListItem['chatCount']
  contactCount: ListItem['contactCount']
}

const adapter = createEntityAdapter<ListItem>({
  selectId: (item) => item._id
})
const initialState = adapter.getInitialState<{ activeId?: ListItem['_id'] }>({
  activeId: undefined
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    updateOne: adapter.updateOne,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    setActiveId (state, action: PayloadAction<ListItem['_id'] | undefined>) {
      state.activeId = action.payload
    }
  }
})

export const { setAll, addOne, updateOne, setActiveId } = slice.actions

// 异步Action
export function getAccountList (params?: Record<string, any>): AppThunk<void> {
  return function (dispatch: Dispatch, getState) {
    return all<ListItem[]>(params).then(({ data, success }) => {
      if (success) {
        dispatch(setAll(data))
        const activeId = getState().account.activeId
        const activeItem = data.find(item => item._id === activeId && item.loginStatus && item.enable)
        if (!activeItem) {
          const validItem = data.find(item => item.loginStatus && item.enable)
          dispatch(setActiveId(validItem?._id))
        }
      }
    })
  }
}

export const { selectAll, selectById } = adapter.getSelectors((state: RootState) => state[name])

export const getOptions = (state: RootState) => {
  let account = selectAll(state)
  account = account.filter(item => item.loginStatus && item.enable)
  return account.map(item => {
    return {
      label: [item.user.first_name, item.user.last_name].filter(Boolean).join(' '),
      value: item._id,
      phone: item.user.phone,
      proxyId: item.proxyId.toUpperCase(),
      chatCount: item.chatCount,
      contactCount: item.contactCount
    } as Options
  })
}
export const getActiveId = (state: RootState) => {
  const account = selectById(state, state[name].activeId!)
  if (account?.enable) {
    return account._id
  }
  return undefined
}
export const getActiveAccount = (state: RootState) => {
  const account = selectById(state, state[name].activeId!)
  return account?.enable ? account : undefined
}

export default slice
