import React from 'react'
import { Result, Button } from 'antd'
import { useNavigate } from 'react-router'

const Index: React.FC = () => {
  const navigate = useNavigate()

  function backHome () {
    navigate('/', { replace: true })
  }

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you don't have access to this page."
      extra={<Button onClick={backHome} type="primary">Back Home</Button>}
    />
  )
}

export default Index
